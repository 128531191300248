@import url(https://fonts.googleapis.com/css?family=Rubik:300,regular,500,600,700,800,900,300italic,italic,500italic,600italic,700italic,800italic,900italic);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css?family=Sofia&effect=neon|outline|emboss|shadow-multiple);
@import url(https://fonts.googleapis.com/css2?family=Racing+Sans+One&display=swap);
@tailwind base;
@tailwind components;
@tailwind utilities;
/* @import url('https://fonts.googleapis.com/css2?family=Bangers&display=swap'); */


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background: radial-gradient(circle at top right,rgba(23,33,94,.9) 0,#f2b04f 25%); */
  background: linear-gradient(45deg, rgb(238, 27, 20), rgb(234, 70, 34));
}

.mybutton {
  color: rgb(241, 239, 239) !important;
  background-color: #13d250 !important;
  box-shadow: none !important;
  transition: background-color 0.3s, -webkit-transform 0.3s;
  transition: background-color 0.3s, transform 0.3s;
  transition: background-color 0.3s, transform 0.3s, -webkit-transform 0.3s;
}

.mybutton:hover {
  color: rgb(119, 6, 122) !important;
  background-color: rgb(16, 16, 15);
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}

/* Add this to your CSS */
.mybutton:hover {
  background-color: green;
  /* You can adjust other styles as needed */
}


element.style {
  font-weight: bold;
  font-size: 60px;
  color: rgb(49, 46, 49);
  margin-top: 20px;
  margin-bottom: 10px;
  margin-left: 10px;
  font-family: monospace;
}
.font-effect-neon {
  text-shadow: 0 0 0.1em #c43636, 0 0 0.2em #f8f4f4, 0 0 0.3em #fff, 0 0 0.4em rgb(248, 245, 248), 0 0 0.6em rgb(239, 230, 239), 0 0 0.8em rgb(244, 237, 244), 0 0 1em rgb(238, 232, 238), 0 0 1.2em rgb(245, 235, 245);
  color: rgb(8, 255, 74);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Racing Sans One', cursive, 'Courier New',
    monospace;
}


.mybutton {
  color: rgb(255, 255, 255) !important;
  background-color: #4e864f !important;
  box-shadow: none !important;
}

.mybutton:hover {
  color:rgb(255, 255, 255) !important;
  background-color: #cd0e0e !important;
}

@media (max-width: 765px) {
  .mybutton {
    color: white !important;
    background-color: #43a5f3 !important;
    box-shadow: none !important;
  }
}

.wrapper {
  border-radius: 10px;
  border: 2px solid #100f0d;
  box-shadow: none !important;
}

.boxContainer {
  display: flex;
  justify-content:baseline;
  padding: 10px;
}

.box {
  width: 30px;
  border: 1px solid #3c3c3c;
  padding: 10px;
  border-radius: 30px; /* Less rounded corners */
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  align-items: auto;
  justify-content: center;
  transition: 0.3s;

}

.box:hover {
  -webkit-transform: scale(1.03);
          transform: scale(1.03);
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
}

.box h2 {
  font-size: 14px;
  color: #333;
  text-decoration: underline;
  font-weight: bold;
  font-family: "Racing Sans One, cursive";
}

.box p {
  font-size: 14px;
  line-height: 1;
  text-align: justify;
  font-family: 'Roboto', sans-serif;
  color: #333;
}

.light-effect::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 10%;
  width: 120%;
  height: 80%;
  background: linear-gradient(to right, transparent 10%, rgba(219, 203, 219, 0.8) 30%, transparent 120%);
  -webkit-animation: lightEffect 7s linear infinite;
          animation: lightEffect 7s linear infinite;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  z-index: 1;
}

/* Add this to your CSS */
.bouncing-image {
  -webkit-animation: bounce 3s infinite;
          animation: bounce 3s infinite;
}


@-webkit-keyframes lightEffect {
  50% {
    left: -50%;
  }
  120% {
    left: 110%;
  }
}


@keyframes lightEffect {
  50% {
    left: -50%;
  }
  120% {
    left: 110%;
  }
}
/* Add this to your CSS */
@-webkit-keyframes bounce {
  0%, 100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(-7px);
            transform: translateY(-7px);
  }
}
@keyframes bounce {
  0%, 100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(-7px);
            transform: translateY(-7px);
  }
}


